/* src/styles/index.css */
@import './components.css';
textarea {
    width: 100%;
    height: 100px;
    margin-bottom: 10px;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
  }
  
  button {
    padding: 10px 15px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }
  
  button:hover {
    background-color: #0056b3;
  }
  
  footer {
    text-align: center;
    padding: 20px;
    background-color: #f8f9fa;
    position: fixed;
    bottom: 0;
    width: 100%;
  }
  