/* Add component-specific styles here */
/* src/styles/components.css */
.major-buttons {
    display: flex;
    flex-wrap: wrap;
    gap: 1rem;
    margin-top: 1rem;
  }
  
  .selected-major {
    margin-top: 2rem;
  }
  
  .skill-card {
    background-color: #ffffff;
    border: 1px solid #e0e0e0;
    border-radius: 5px;
    padding: 1rem;
    width: calc(33.333% - 1rem);
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }
  
  .skill-card h4 {
    margin-top: 0;
    margin-bottom: 0.5rem;
  }
  
  .verify-btn {
    background-color: #28a745;
    color: white;
    border: none;
    padding: 0.5rem 1rem;
    border-radius: 3px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .verify-btn:hover:not(.disabled) {
    background-color: #218838;
  }
  
  .verify-btn.disabled {
    background-color: #6c757d;
    cursor: not-allowed;
    opacity: 0.5;
  }